<template>
  <div class="p-4 pb-8 bg-white rounded-lg">
    <div class="px-4 mb-7 mx-4 mt-6 d-flex align-center justify-space-between">
      <datatable-detail
        class="col-9 col-md-9 col-sm-9"
        :table_properties="tableProperties"
      />
      <div v-if="$store.getters?.getSHIPPINGCOUNTRIESOrganizations">
        <v-select
          class="w-250px me-6"
          v-model="organization_id"
          label="Organization"
          item-text="text"
          item-value="index"
          :items="$store.getters.getSHIPPINGCOUNTRIESOrganizations"
          clearable
          outlined
          dense
        />
      </div>
    </div>
    <div
      class="custom-container-height w-100 mx-0 pb-3 px-0 px-sm-2 px-md-8 d-flex flex-wrap poppins"
    >
      <NestedDataTable
        tableName="countries"
        editType="country"
        :handleAddFunction="() => handleAddFunction('country')"
        :handleGetFunction="getStates"
        :showExportSideBar="showExportSideBar"
      />
      <NestedDataTable
        tableName="states"
        editType="state"
        :handleAddFunction="() => handleAddFunction('state')"
        :handleGetFunction="getCities"
        :showExportSideBar="showExportSideBar"
      />
      <NestedDataTable
        tableName="cities"
        editType="city"
        :handleAddFunction="() => handleAddFunction('city')"
        :handleGetFunction="getAreas"
        :showExportSideBar="showExportSideBar"
      />
      <NestedDataTable
        tableName="areas"
        editType="area"
        :handleAddFunction="() => handleAddFunction('area')"
        :showExportSideBar="showExportSideBar"
      />
    </div>
    <AddArea ref="addAreaDialog" />
    <AddCity ref="addCityDialog" />
    <AddState ref="addStateDialog" />
    <AddCountry ref="addCountryDialog" />
    <ExportSidebar
      ref="exportSidebar"
      :exportHeaders="getExportColumns"
      :downloadItem="downloadItem"
      :submitFilterValues="() => {}"
      :resetFilterValues="() => {}"
      :filters="[]"
      :setTableFiltersValues="() => {}"
    />
  </div>
</template>

<script>
import ExportSidebar from "@/own/components/sidebars/ExportSidebar.vue";
import DatatableDetail from "@/own/components/datatable/DatatableDetail";
import countryNestedDataMixin from "@/own/mixins/countryNestedDataMixin";
import AddArea from "@/own/components/shipping/countries/Add/AddArea";
import AddCity from "@/own/components/shipping/countries/Add/AddCity";
import AddState from "@/own/components/shipping/countries/Add/AddState";
import AddCountry from "@/own/components/shipping/countries/Add/AddCountry";
import NestedDataTable from "@/own/components/shipping/countries/NestedDataTable";
import { SET_PAGE_LOADING } from "@/core/services/store/config.module";
import ApiService from "@/core/services/api.service";

export default {
  name: "NestedData",
  components: {
    NestedDataTable,
    AddCountry,
    AddState,
    AddCity,
    AddArea,
    DatatableDetail,
    ExportSidebar,
  },
  mixins: [countryNestedDataMixin],
  data: () => ({
    exportColumns: [],
    exportType: null,
  }),
  computed: {
    getExportColumns() {
      return this.exportColumns;
    },
  },
  methods: {
    showExportSideBar(columns, type) {
      this.exportColumns = columns;
      this.type = type;
      this.$nextTick(() => {
        this.$refs.exportSidebar.$refs.export.initiate();
        this.$refs.exportSidebar.$data.exportSidebar = true;
      });
    },
    downloadItem(columns) {
      if (columns.length == 0) {
        return;
      }
      this.$store.commit(SET_PAGE_LOADING, true);
      let data = { type: this.type, columns: columns };
      if (this.$store.getters.getSHIPPINGCOUNTRIESSelectedCityId) {
        data = {
          ...data,
          country_id: this.$store.getters.getSHIPPINGCOUNTRIESSelectedCountryId,
          state_id: this.$store.getters.getSHIPPINGCOUNTRIESSelectedStateId,
          city_id: this.$store.getters.getSHIPPINGCOUNTRIESSelectedCityId,
        };
      } else if (this.$store.getters.getSHIPPINGCOUNTRIESSelectedStateId) {
        data = {
          ...data,
          country_id: this.$store.getters.getSHIPPINGCOUNTRIESSelectedCountryId,
          state_id: this.$store.getters.getSHIPPINGCOUNTRIESSelectedStateId,
        };
      } else if (this.$store.getters.getSHIPPINGCOUNTRIESSelectedCountryId) {
        data = {
          ...data,
          country_id: this.$store.getters.getSHIPPINGCOUNTRIESSelectedCountryId,
        };
      }
      ApiService.post("/shipping/countries/export", data)
        .then((response) => {
          const link = document.createElement("a");
          link.href = response.data.url;
          link.download = "country.csv";
          link.click();
          setTimeout(() => {
            this.$store.commit(SET_PAGE_LOADING, false);
          }, 1500);
          this.$refs.exportSidebar.$refs.export.toggleModal();
          link.remove();
        })
        .catch(() => {
          this.$store.commit(SET_PAGE_LOADING, false);
          this.$refs.exportSidebar.$refs.export.toggleModal();
        });
    },
  },
};
</script>

<style scoped>
.custom-container-height {
  overflow-y: scroll;
  position: relative;
  height: calc(100vh - 327px) !important;
}

@media (max-width: 1199.98px) {
  .custom-container-height {
    height: calc(100vh - 300px) !important;
  }
}

@media (max-width: 991.98px) {
  .custom-container-height {
    height: calc(100vh - 285px) !important;
  }
}

@media (max-width: 768px) {
  .custom-container-height {
    height: calc(100vh - 275px) !important;
  }
}
</style>
