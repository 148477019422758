<template>
  <section class="custom-width d-flex flex-column px-3">
    <table :id="`${tableName}`" style="table-layout: auto">
      <thead>
        <tr>
          <th
            class="border px-4 py-2 text-center text-capitalize font-weight-bolder font-size-h5"
          >
            {{ tableName }}
            <div class="float-right">
              <button @click="handleAddFunction" class="pa-0 btn btn-icon-info">
                <span class="svg-icon">
                  <v-icon size="24">mdi-plus-box-outline</v-icon>
                </span>
              </button>
              <button
                @click="handleExportFunction"
                class="pa-0 btn btn-icon-info ma-0"
              >
                <!--                v-if="editType !== 'country'"-->
                <span class="svg-icon">
                  <v-icon size="24">mdi-file-download-outline</v-icon>
                </span>
              </button>
            </div>
          </th>
        </tr>
        <tr>
          <th class="border px-4 py-2">
            <input
              :id="`search-${tableName}`"
              type="search"
              class="border custom-input rounded w-100 py-2 px-3 text-dark-75"
              @keyup="filterInputs"
              :placeholder="`Search ${tableName}..`"
            />
          </th>
        </tr>
      </thead>
      <tbody :class="`${tableName}-tb`">
        <!--        <div style="height: 48vh; overflow-y: auto">-->
        <template v-if="items.length === 0 && tableName !== 'countries'">
          <tr>
            <td class="border px-4 py-2">{{ label }}</td>
          </tr>
        </template>
        <template v-else>
          <div class="" style="max-height: 55vh !important; overflow-y: scroll">
            <tr v-for="item in items" :key="item.id" class="d-block">
              <td
                class="border px-4 py-2 d-flex justify-space-between align-center"
              >
                <span
                  v-if="tableName !== 'countries'"
                  class="font-size-lg py-1 font-weight-medium ml-2"
                >
                  {{ item.title }}
                </span>
                <span v-else class="font-size-lg py-1 font-weight-medium ml-2">
                  {{ item.title }}
                  <span
                    class="d-inline-block bg-gray-200 rounded-lg ml-2 px-3 text-dark-65"
                  >
                    {{
                      item.systemCode !== null
                        ? `${item.shortName} , ${item.systemCode}`
                        : `${item.shortName}`
                    }}
                  </span>
                </span>
                <div class="d-flex align-center justify-end">
                  <ActionsMenu :item-id="item.id" :editType="editType" />

                  <button
                    v-if="tableName !== 'areas'"
                    @click="(event) => handleGetFunction(item.id, event)"
                    class="btn btn-sm btn-icon btn-circle btn-bg-light btn-hover-light-info"
                  >
                    <span class="svg-icon">
                      <v-icon size="19"> mdi-arrow-right </v-icon>
                    </span>
                  </button>
                </div>
              </td>
            </tr>
          </div>
        </template>
        <!--        </div>-->
      </tbody>
    </table>
  </section>
</template>

<script>
import ActionsMenu from "@/own/components/shipping/countries/ActionsMenu";
import Swal from "sweetalert2";

export default {
  name: "NestedDataTable",
  components: { ActionsMenu },
  props: {
    tableName: {
      type: String,
      required: true,
    },
    editType: {
      type: String,
      required: true,
    },
    handleAddFunction: {
      type: Function,
      required: true,
    },
    handleGetFunction: {
      type: Function,
      required: false,
    },
    showExportSideBar: {
      type: Function,
      required: true,
    },
  },
  computed: {
    exportHeaders() {
      return this.$store.getters.getSHIPPINGCOUNTRIESExportColumns[
        `${this.editType}`
      ].columns;
      // return this.editType == "country"
      //   ? []
      //   : this.$store.getters.getSHIPPINGCOUNTRIESExportColumns[
      //       `${this.editType}`
      //     ].columns;
    },
    items: function() {
      if (this.editType === "country")
        return this.$store.getters.getSHIPPINGCOUNTRIESCountriesData;
      else if (this.editType === "state")
        return this.$store.getters.getSHIPPINGCOUNTRIESStatesData;
      else if (this.editType === "city")
        return this.$store.getters.getSHIPPINGCOUNTRIESCitiesData;
      else return this.$store.getters.getSHIPPINGCOUNTRIESAreasData;
    },
    label: function() {
      if (this.editType === "state")
        return this.$store.getters.getSHIPPINGCOUNTRIESStatesLabel;
      else if (this.editType === "city")
        return this.$store.getters.getSHIPPINGCOUNTRIESCitiesLabel;
      else return this.$store.getters.getSHIPPINGCOUNTRIESAreasLabel;
    },
  },
  methods: {
    handleExportFunction() {
      if (this.editType === "country")
        this.showExportSideBar(this.exportHeaders, this.editType);
      else if (this.editType === "state") {
        if (!this.$store.getters.getSHIPPINGCOUNTRIESSelectedCountryId) {
          Swal.fire({
            title: `Please, select country`,
            icon: "warning",
            confirmButtonClass: "btn btn-primary",
          });
        } else this.showExportSideBar(this.exportHeaders, this.editType);
      } else if (this.editType === "city") {
        if (!this.$store.getters.getSHIPPINGCOUNTRIESSelectedStateId) {
          Swal.fire({
            title: `Please, select state`,
            icon: "warning",
            confirmButtonClass: "btn btn-primary",
          });
        } else this.showExportSideBar(this.exportHeaders, this.editType);
      } else {
        if (!this.$store.getters.getSHIPPINGCOUNTRIESSelectedCityId) {
          Swal.fire({
            title: `Please, select city`,
            icon: "warning",
            confirmButtonClass: "btn btn-primary",
          });
        } else this.showExportSideBar(this.exportHeaders, this.editType);
      }
    },
    filterInputs() {
      let input, filter, table, tr, td, i, txtValue;
      input = document.getElementById("search-" + this.tableName);
      filter = input.value.toUpperCase();
      table = document.getElementById(this.tableName);
      tr = table.getElementsByTagName("tr");

      // Loop through all table rows, and hide those who don't match the search query
      for (i = 0; i < tr.length; i++) {
        td = tr[i].getElementsByTagName("td")[0];
        if (td) {
          txtValue = td.textContent || td.innerText;
          if (txtValue.toUpperCase().indexOf(filter) > -1) {
            tr[i].style.display = "";
          } else {
            tr[i].style.display = "none";
          }
        }
      }
    },
  },
};
</script>

<style scoped>
td:hover {
  background: #fafafa;
}

.custom-input {
  box-shadow: 0 1px 3px 0 rgb(0 0 0 / 10%), 0 1px 2px 0 rgb(0 0 0 / 6%);
  outline: none;
  font-weight: normal;
}

.custom-input:focus {
  box-shadow: 0 0 0 3px rgb(66 153 225 / 50%);
  outline: 2px solid transparent;
  outline-offset: 2px;
}

.custom-width {
  width: 100%;
}

@media (min-width: 769px) {
  .custom-width:first-of-type {
    width: 31%;
  }

  .custom-width {
    width: 23%;
  }
}

/*.fixed_header tbody {*/
/*  display: block;*/
/*  -ms-overflow-style: none; !* for Internet Explorer, Edge *!*/
/*  scrollbar-width: none; !* for Firefox *!*/
/*  overflow-y: scroll;*/
/*  height: calc(100vh - 420px);*/
/*}*/

/*.fixed_header tbody::-webkit-scrollbar {*/
/*  display: none; !* for Chrome, Safari, and Opera *!*/
/*}*/
</style>
